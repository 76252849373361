import { nanoid } from "nanoid";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api, {
  sendWSSubscritption as sendWSSubscription,
  sendWSUpdate,
  wspInstance,
} from "src/backendApi";
import { SubscriptionType, UpdateType, User } from "src/structures/Types";
import {
  Button,
  Column,
  Header2,
  Header3,
  ListItem,
  NavButton,
  Row,
  Text,
  Ul,
} from "src/styles/components";

const MonitorMenu = () => {
  const { pokerUid } = useParams();

  if (pokerUid === undefined) {
    throw new Error("everything is on fire!");
  }

  useEffect(() => {
    const openWS = async () => {
      await wspInstance.open();
      let users = await api.retrieveUsers(pokerUid);
      setUsers(users);
    };

    openWS();

    sendWSSubscription(SubscriptionType.WatchUsers, pokerUid);

    wspInstance.onMessage.addListener(async (e) => {
      console.log("received message", e, "of type", typeof e);
      let msg = JSON.parse(e);
      switch (msg.content) {
        case UpdateType.RefreshUsers:
          let users = await api.retrieveUsers(pokerUid);
          setUsers(users);
      }
    });
  }, []);

  const [users, setUsers] = useState<User[]>([]);

  return (
    <Column style={{ margin: "auto" }}>
      <Header2>
        Share this link to let others play the Poker you just created!
      </Header2>
      <Row style={{ margin: "auto" }}>
        <Text>https://planning-poker.cr.trineria.fi/play/{pokerUid}/</Text>
        <Button
          type="button"
          onClick={() =>
            navigator.clipboard.writeText(
              `https://planning-poker.cr.trineria.fi/play/${pokerUid}/`
            )
          }
        >
          Copy
        </Button>
      </Row>

      {users.length > 0 ? (
        <>
          <Column style={{ margin: "auto" }}>
            <Text>
              Here are all the users that have already entered the Poker
            </Text>
            <Ul>
              {users.map((user) => (
                <ListItem>{user.name}</ListItem>
              ))}
            </Ul>
          </Column>
          <NavButton
            onClick={() => sendWSUpdate(UpdateType.FinishPoker, pokerUid)}
            as={Link}
            to={`/results/${pokerUid}`}
          >
            Finish Poker
          </NavButton>
        </>
      ) : (
        <></>
      )}

{/*       {users.length > 0 ? (
        <NavButton
          onClick={() => sendWSUpdate(UpdateType.FinishPoker, pokerUid)}
          as={Link}
          to={`/results/${pokerUid}`}
        >
          Finish Poker
        </NavButton>
      ) : (
        <></>
      )} */}
    </Column>
  );
};

export default MonitorMenu;
