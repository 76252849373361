import { ConnectDropTarget, useDrop } from "react-dnd";
import { Ticket } from "../../../structures/Types";
import { useDispatch, useSelector } from "react-redux";
import {
  removeOpinion,
  selectCurrentUser,
  selectTickets,
} from "../../../app/store";
import {
  Border,
  CenteredButton,
  Text,
  ListItem,
  Column,
  Ol,
  Circle,
} from "src/styles/components";

type EstimationScalePointProps = {
  value: number;
  uid: string;
  setTouched: Function
};

const EstimationScalePoint = (props: EstimationScalePointProps) => {
  //potentially implement more than one scale in the future but for now let's just take the 0th item in list
  const [, drop] = useDrop(() => ({
    accept: "ticket",

    // we return the uid of the current point on the scale so the ticket knows where
    // it has been dropped
    drop: () => {
      return { scalePointUid: props.uid };
    },
  }));

  return (
    <ListItem key={props.value}>
      <Column style={{margin: "auto"}}>
        <Circle primary>
          <Text>{props.value}</Text>
        </Circle>
        <DropBox drop={drop} uid={props.uid} setTouched={props.setTouched} />
      </Column>
    </ListItem>
  );
}

const DropBox = (props: { drop: ConnectDropTarget; uid: string; setTouched: Function }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const tickets = useSelector(selectTickets);

  const relatedTicketUids: string[] = currentUser.opinions
    .filter((opinion) => opinion.opinionUid === props.uid)
    .map((opinion) => opinion.ticketUid);

  const relatedTickets: Ticket[] = tickets.filter((ticket) =>
    relatedTicketUids.includes(ticket.uid)
  );

  const removeTicketFromScalePoint = (uid: string) => {
    dispatch(removeOpinion(uid));
    props.setTouched(true)
  };

  //Check if the scalepoint is null or has an empty ticket
  if (relatedTickets.length === 0) {
    return (
      <Column style={{margin: "auto"}}>
        <Border ref={props.drop}>
          <Text>Add a ticket!</Text>
        </Border>
      </Column>
    );
  } else {
    return (
      <>
        <Ol>
          {relatedTickets.map((ticket) => {
            return (
              <ListItem key={ticket.uid}>
                <Border primary>
                  <Text>{ticket.name}</Text>

                  <CenteredButton
                    onClick={() => removeTicketFromScalePoint(ticket.uid)}
                  >
                    ×
                  </CenteredButton>
                </Border>
              </ListItem>
            );
          })}
        </Ol>
        <Border ref={props.drop}>
          <Text>Add a ticket!</Text>
        </Border>
      </>
    );
  }
};


export default EstimationScalePoint