export enum TicketState {
  Uninitialized,
  Unestimated,
  Estimated,
  UnderReview,
}

export type DropResult = {
  scalePointUid: string;
};

export type Ticket = {
  name: string;
  uid: string;
  desc: string;
};

export type ScalePoint = {
  value: number;
  uid: string;
};

export type EstimationScale = {
  scalepoints: ScalePoint[];
};

export type User = {
  opinions: Opinion[];
  name: string;
  uid: string;
};

export type Opinion = {
  ticketUid: string;
  opinionUid: string;
};

export type Result = {
  ticketName: string
  estimationValue: number
}

export type Poker = {
  name: string
  users: User[]
  tickets: Ticket[]
  uid: string
  estimationScale: EstimationScale
  pokerStatus: PokerStatus
}

export type PokerMetaData = {
  name: string,
  uid: string,
  pokerStatus: PokerStatus
}

//GraphQL payload types
//These are supposed to be identical to the ones in the F# backend
// with the same names. If they're not, everything will break!
export type AddUserPayload = {
  pokerUid: string;
  name: string;
  opinions: Opinion[]
}

export type CreatePokerPayload = {
  tickets: Ticket[];
  estimationScale: EstimationScale;
  name: string;
}

export type UpdateUserPayload = {
  userUid: string;
  pokerUid: string;
  opinions: Opinion[]
}

export enum PokerStatus {
  InProgress = "InProgress",
  Finished = "Finished"
}

export enum MessageType {
  Query = 100,
  StatusUpdate = 101,
  Subscription = 102
}

export enum UpdateType {
  RefreshUsers = 200,
  FinishPoker = 201
}

export enum SubscriptionType {
  WatchUsers = 300,
  WatchPokerStatus = 301
}

export type WebSocketMessage = {
  messageType: MessageType,
  content: string,
}
