import "./App.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import AppRouter from "./components/views/welcome/Welcome";
import { RootContainer } from "./styles/components";

function App() {

  document.title = "Planning Poker";

  return (
    <DndProvider backend={HTML5Backend} debugMode={true}>
      <RootContainer>
        <AppRouter />
      </RootContainer>
    </DndProvider>
  );
}

export default App;
