import styled from "styled-components";

type BorderProps = {
  primary?: boolean;
}

type LayoutProps = {
  grow? : number;
}

export const Input = styled.input`
  border: #fe330a 0.25em solid;
  border-radius: 0.5rem;
  padding: 0.5em;
  background-color: transparent;
  font-family: paralucent, sans-serif;
  font-weight: lighter;

  :focus {
    outline: none;
    background-color: white;
  }
`;
export const BigInput = styled(Input)`
  font-size: 2rem;
  margin: 0.5em;
`;

export const TextArea = styled.textarea`
  border: #fe330a 0.25em solid;
  border-radius: 0.5rem;
  padding: 0.5em;
  background-color: transparent;
  font-family: paralucent, sans-serif;
  font-weight: lighter;

  :focus {
    outline: none;
    background-color: white;
  }

`
export const Button = styled.button`

display: inline-flex;
align-items: center;
justify-content: center;
background-color: #fe330a;
color: white;
border-radius: 2em;
padding: 0.75em;
border-color: #fe330a;
border-style: solid;
font-size: 0.8em;
margin: 1em;
font-family: paralucent, sans-serif;
font-weight: 600;
height: 4em;
min-width: 5em;
text-decoration: none;

transition: 0.25s;

:hover {
  background-color: #c02203;
  border-color: #c02203;
}

:disabled {
  background-color: #FFF;
}
`;

export const CenteredButton = styled(Button)`
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const BigButton = styled(CenteredButton)`
  font-size: 1.5em;
`;
export const NavButton = styled(BigButton)`
  cursor: pointer;
`;

const HeaderBase = `
text-align: center;
align-self: center;
color: #1c1c1c;
font-family: paralucent, sans-serif;
font-weight: 600;
margin: 0.25em 0;

`;

export const Header1 = styled.h1`
  ${HeaderBase}
  font-size: 5em;
`;

export const Header2 = styled.h2`
  ${HeaderBase}
  font-size: 3em;
`
export const Header3 = styled.h3`
  ${HeaderBase}
  font-size:1.5em;
`;

export const Header4 = styled.h4`
  ${HeaderBase}
  font-size: 1em;
`

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Column = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  ${props => props.grow !== undefined ? `flex-grow: ${props.grow}` : ''}
  
`;



export const Row = styled.div<LayoutProps>`
  display: flex;
  flex-direction: row;
  ${props => props.grow !== undefined ? `flex-grow: ${props.grow}` : ''}

  
`;

export const Border = styled.div<BorderProps> `
border: 0.25rem solid;
border-radius: 1rem;
padding: 2em;
margin: 0.5em;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
border-color: ${props => props.primary ? " #fe330a" : "#000000" }
`


export const ThickBorder = styled(Border)`
border-width: 0.5rem;
`
export const Circle = styled(Border)`
border-radius: 50%;
display:flex;
margin:auto;
padding:1em;
width: 1em;
height: 1em;
`

export const HorizontalPanelLayout = styled.div`
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 3em;
  align-items: flex-start;
  justify-content: center;
`;

const List = `
list-style-type: none;
padding: 0;

`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
`

export const GridItem = styled.div`
  display: inline-grid  
`


export const Ol = styled.ol`
  ${List}
`;


export const Ul = styled.ul`
  ${List}
`;



export const ListItem = styled.li`
  display: flex;
  justify-content: center;
  margin: auto;
`

export const DropDown = styled.select`
`

export const DropDownItem = styled.option`
`


export const Text = styled.span`
  display: flex;
  margin: auto;
  font-family: paralucent, sans-serif;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  overflow-wrap: break-word;
  max-width: 15em;

`
