import React, { useEffect, useState } from "react";
import {
  EstimationScale,
  PokerStatus,
  ScalePoint,
  Ticket,
} from "src/structures/Types";

import { nanoid } from "nanoid";
import api from "../../../backendApi";
import {
  BigButton,
  CenteredButton,
  Header2,
  Header3,
  Header4,
  Ol,
  ListItem,
  Input,
  Ul,
  Row,
  Text,
  TextArea,
  Border,
  Column,
  GridContainer,
  GridItem,
  Button,
} from "../../../styles/components";
import { useNavigate } from "react-router-dom";

const PokerCreator = () => {
  const navigate = useNavigate();

  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [estimationScale, setEstimationScale] = useState<EstimationScale>({
    scalepoints: [],
  });
  const [newScalePoint, setNewScalePoint] = useState<ScalePoint>({
    value: NaN,
    uid: nanoid(),
  });

  const [pokerName, setPokerName] = useState<string>("");

  const [newTicket, setNewTicket] = useState<Ticket>({
    name: "",
    uid: nanoid(),
    desc: "",
  });

  const scalePointSubmit = () => {
    console.log("called scalePointSubmit");
    if (
      //if it's already in the estimation scale or it's NaN don't add it
      estimationScale.scalepoints.some(
        (estimationScalePoint) =>
          estimationScalePoint.value === newScalePoint.value
      ) ||
      isNaN(newScalePoint.value)
    ) {
      setNewScalePoint({
        value: NaN,
        uid: nanoid(),
      });
      alert("You can't add scalepoints that are empty or already exist!");
      return;
    }

    console.log("Scalepoint", newScalePoint, "added");

    //reset the new adminscalepoint
    setNewScalePoint({
      value: NaN,
      uid: nanoid(),
    });

    const newScale = [...estimationScale.scalepoints, newScalePoint].sort(
      (a, b) => a.value - b.value
    );
    setEstimationScale({ scalepoints: newScale });
  };

  const ticketSubmit = () => {
    if (
      newTicket.name !== "" &&
      tickets.filter((ticket) => ticket.name === newTicket.name).length === 0
    ) {
      setTickets([...tickets, newTicket]);
      setNewTicket({ name: "", desc: "", uid: nanoid() });
    }
  };

  const clearScalePoints = () => {
    setEstimationScale({ scalepoints: [] });
  };

  const beginPoker = async () => {
    if (tickets.length === 0) {
      alert("You need to add some tickets before starting a Poker!");
      return;
    }

    if (estimationScale.scalepoints.length === 0) {
      alert("You need to add some scalepoints before starting a Poker!");
      return;
    }

    if (pokerName === "") {
      alert("You haven't given your Poker a name!");
      return;
    }
    const pokerUid = await api.createPoker({
      tickets: tickets,
      estimationScale: estimationScale,
      name: pokerName,
    });

    navigate(`/monitor/${pokerUid}`);
  };
  return (
    <>
      <Header2>Prepare a Planning Poker</Header2>

      <Row style={{ margin: "auto" }}>
        <Border primary>
          <Column
            style={{ margin: "auto" }}
            as="form"
            onSubmit={(e: React.FormEvent) => {
              e.preventDefault();

              ticketSubmit();
            }}
          >
            <Header3>Enter a new ticket for this Poker</Header3>

            <Header4 as="label" htmlFor="ticketNameEntry">
              Name:
            </Header4>

            <Input
              onChange={(e) =>
                setNewTicket({ ...newTicket, name: e.target.value })
              }
              type="text"
              value={newTicket.name}
              id="ticketNameEntry"
            />

            <Header4 as="label" htmlFor="ticketDescEntry">
              Description:
            </Header4>

            <TextArea
              onChange={(e) =>
                setNewTicket({ ...newTicket, desc: e.target.value })
              }
              value={newTicket.desc}
              id="ticketDescEntry"
            />

            <CenteredButton
              type="button"
              onClick={() => {
                ticketSubmit();
              }}
            >
              Create new ticket
            </CenteredButton>
          </Column>
          <Ul>
            <GridContainer>
              {tickets.length !== 0 ? (
                tickets.map((ticket, index) => (
                  <ListItem key={ticket.uid}>
                    <GridItem>
                      <Border>
                        <Header3>{ticket.name}</Header3>

                        <Text>{ticket.desc}</Text>

                        <Column style={{ margin: "auto" }}>
                          <CenteredButton
                            onClick={() => {
                              setTickets(
                                tickets.filter(
                                  (ticket2) => ticket2.uid !== ticket.uid
                                )
                              );
                            }}
                          >
                            Remove
                          </CenteredButton>
                        </Column>
                      </Border>
                    </GridItem>
                  </ListItem>
                ))
              ) : (
                <Border>
                  <Text>Add some tickets!</Text>
                </Border>
              )}
            </GridContainer>
          </Ul>
        </Border>

        <Border primary>
          <Column
            as="form"
            onSubmit={(e: { preventDefault: () => void }) => {
              e.preventDefault();
              scalePointSubmit();
            }}
          >
            <Header3 as="label" htmlFor="scalePointEntry">
              Enter a new scalepoint for this Poker
            </Header3>

            <Input
              onChange={(e) =>
                setNewScalePoint({
                  ...newScalePoint,
                  value: isNaN(parseInt(e.target.value))
                    ? 0
                    : parseInt(e.target.value),
                })
              }
              type="text"
              value={isNaN(newScalePoint.value) ? "" : newScalePoint.value}
              id="scalePointEntry"
            />
            <CenteredButton type="button" onClick={scalePointSubmit}>
              Create new scalepoint
            </CenteredButton>

            <Ol>
              <GridContainer>
                {estimationScale.scalepoints.map((scalePoint) => (
                  <ListItem value={scalePoint.value} key={scalePoint.value}>
                    <GridItem>
                      <Row style={{ margin: "auto" }}>
                        <Border>
                          <Text>{scalePoint.value}</Text>
                          <CenteredButton
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();

                              let points = estimationScale.scalepoints.filter(
                                (scalePoint2) => {
                                  console.log(
                                    "scalePoint2",
                                    scalePoint2,
                                    "scalePoint",
                                    scalePoint
                                  );
                                  console.log(
                                    "uid of the scalepoint",
                                    scalePoint2.uid,
                                    "will it be kept",
                                    scalePoint2.uid !== scalePoint.uid
                                  );

                                  return scalePoint2.uid !== scalePoint.uid;
                                }
                              );
                              console.log("filtered points", points);
                              setEstimationScale({ scalepoints: points });
                            }}
                          >
                            ×
                          </CenteredButton>
                        </Border>
                      </Row>
                    </GridItem>
                  </ListItem>
                ))}
              </GridContainer>
            </Ol>
            {estimationScale.scalepoints.length === 0 ? (
              <Border>
                <Text>Add some scalepoints!</Text>
              </Border>
            ) : (
              <CenteredButton type="button" onClick={clearScalePoints}>
                Clear
              </CenteredButton>
            )}
          </Column>
        </Border>
      </Row>

      <Column style={{ margin: "auto" }}>
        <Header2 as="label" htmlFor="pokerNameEntry">
          Finally, give your Poker a name!
        </Header2>
        <Input
          onChange={(e) => setPokerName(e.target.value)}
          type="text"
          value={pokerName}
          id="pokerNameEntry"
        />
        <BigButton onClick={() => beginPoker()} className="big-button">
          Begin Poker
        </BigButton>
      </Column>
    </>
  );
};

export default PokerCreator;
