import api, { wspInstance } from "../../../backendApi";
import { PokerMetaData, PokerStatus, User } from "../../../structures/Types";
import EMenu from "../user/EMenu";
import PokerCreator from "../admin/PokerCreator";
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { Route, Link, Routes, useNavigate } from "react-router-dom";
import {
  /*   BigInput,
  BigButton,
 */ Column,
  Header1,
  /*   Header2,
   */ NavButton,
  ThickBorder,
  Row,
  
} from /*   DropDownItem,
  DropDown,
 */ "../../../styles/components";
/* import { setCurrentUser } from "src/app/store";
import { useDispatch, useSelector } from "react-redux";
 */ import PokerResults from "../results/PokerResults";
import MonitorMenu from "../admin/MonitorMenu";
function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<WelcomeScreen />} />
      <Route path="/create" element={<PokerCreator />} />
      <Route path="/play/:pokerUid" element={<EMenu />} />
      <Route path="/monitor/:pokerUid" element={<MonitorMenu />} />
      <Route path="/results/:pokerUid" element={<PokerResults />} />
    </Routes>
  );
}

/* const testApiCall = async () => {
  console.log("test API call");

  let users = await api.retrieveUsers("EnM7VW5W5JdC4fv28S_Jy");

  console.log("users", users);
}; */

function WelcomeScreen() {
  /*   const navigate = useNavigate();

  const dispatch = useDispatch();
  const [newUser, setNewUser] = useState<User>({
    name: "",
    uid: nanoid(),
    opinions: [],
  } as User); */

  /*   const [chosenPokerUid, setChosenPokerUid] = useState("");
   */
  /*   const [activePokers, setActivePokers] = useState<PokerMetaData[]>([]);
   */
/*   useEffect( () => {

    const openWS = async () => {
      await wspInstance.open()
    }

    openWS()

  })
 */

  /*   const playPoker = (pokerUid: string) => {
    if (newUser.name !== "" && pokerUid !== "") {
      dispatch(setCurrentUser(newUser));
      navigate(`/play/${pokerUid}`);
      setNewUser({ name: "", uid: nanoid(), opinions: [] });
    }
  };
 */

  
  return (
    <Column style={{margin: "auto"}}
    /*       as="form"
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();
        playPoker(chosenPokerUid);
      }}
 */
    >
      <Header1>Welcome!</Header1>

      {/*       <Row style={{margin: "auto"}}>
        <Column style={{margin: "auto"}}>
          <Header2 as="label" htmlFor="user-name-input">
            Enter your name
          </Header2>
          <BigInput
            id="user-name-input"
            type="text"
            value={newUser.name}
            onChange={(e) =>
              setNewUser({ ...newUser, name: e.target.value || "" })
            }
          />
        </CenteredColumn>

        <Column style={{margin: "auto"}}>
          <Header2 as="label" htmlFor="poker-select">
            Choose an active Poker
          </Header2>

          <DropDown
            id="poker-select"
            onChange={(e) => {
              setChosenPokerUid(e.target.value);
            }}
          >
            {activePokers.map((poker) => {
              if (poker.pokerStatus === PokerStatus.InProgress)
                return (
                  <DropDownItem key={poker.uid} value={poker.uid}>
                    {poker.name}
                  </DropDownItem>
                );
            })}
          </DropDown>
        </CenteredColumn>
      </CenteredRow>

      <NavButton onClick={(e) => playPoker(chosenPokerUid)}>Go!</NavButton> */}
      <Row style={{margin: "auto"}}>
        <ThickBorder primary>
          {/*           <Header2>Or...</Header2>
           */}{" "}
          <NavButton as={Link} to="/create">
            Create a new Poker
          </NavButton>
        </ThickBorder>
      </Row>
    </Column>
  );
}

export default AppRouter;
