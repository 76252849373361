import produce from "immer";
import { createStore, compose, AnyAction, Reducer } from "redux";
import api from "../backendApi";
import {
  EstimationScale,
  ScalePoint,
  Ticket,
  User,
  Opinion,
} from "../structures/Types";

type RootState = {
  tickets: Ticket[];
  estimationScale: EstimationScale;
  currentUser: User;
};


const rootState: RootState = {
  tickets: [],
  estimationScale: { scalepoints: []},
  currentUser: {
    opinions: [],
    name: "",
    uid: "",
  },
};
export enum ActionType {
  SET_TICKETS,
  SET_ESTIMATION_SCALE,
  SET_CURRENT_USER,

  CREATE_OPINION,
  RESET_OPINIONS,
  DELETE_OPINION,

  DELETE_TICKET,
}

const rootReducer: Reducer<RootState> = (
  state: RootState = rootState,
  action: AnyAction
) => {
  console.log(action);

  switch (action.type) {
    case ActionType.SET_ESTIMATION_SCALE:
      return produce(state, (draftState) => {
        draftState.estimationScale.scalepoints = action.payload.points;
      });

    case ActionType.CREATE_OPINION:
      return produce(state, (draftState) => {

        //add new opinion
        draftState.currentUser.opinions.push(action.payload.opinion);
      });

    case ActionType.RESET_OPINIONS:
      return produce(state, (draftState) => {
        
        draftState.currentUser.opinions = [];
      });

    case ActionType.DELETE_OPINION:
      return produce(state, (draftState) => {
        draftState.currentUser.opinions = draftState.currentUser.opinions.filter(
          (opinion) => {
            return opinion.ticketUid !== action.payload.ticketUid;
          }
        );
      });

    case ActionType.DELETE_TICKET:
      return produce(state, (draftState) => {
        draftState.tickets = draftState.tickets.filter((ticket) => {
          return ticket.uid !== action.payload.ticketUid;
        });
      });

    case ActionType.SET_TICKETS:
      return produce(state, (draftState) => {
        draftState.tickets = action.payload.tickets;
      });


     case ActionType.SET_CURRENT_USER:
      return produce(state, (draftState) => {
        draftState.currentUser = action.payload.user;
      });
  }

  return state;
};

//Action creators

export const setTickets = (tickets: Ticket[]) => {
  return {
    type: ActionType.SET_TICKETS,
    payload: {
      tickets: tickets,
    },
  };
};

export const setCurrentUser = (user: User) => {
  return {
    type: ActionType.SET_CURRENT_USER,
    payload: {
      user: user,
    },
  };
};
  
export const setEstimationScale = (points: ScalePoint[]) => {
  return {
    type: ActionType.SET_ESTIMATION_SCALE,
    payload: {
      points: points,
    },
  };
};

export const createOpinion = (opinion: Opinion) => {
  return {
    type: ActionType.CREATE_OPINION,
    payload: {
      opinion: opinion,
    },
  };
};


export const resetUserSelections = () => {
  return { type: ActionType.RESET_OPINIONS };
};

export const removeOpinion = (ticketUid: string) => {
  return {
    type: ActionType.DELETE_OPINION,
    payload: {
      ticketUid: ticketUid,
    },
  };
};

export const deleteTicket = (ticketUid: string) => {
  return {
    type: ActionType.DELETE_TICKET,
    payload: {
      ticketUid: ticketUid,
    },
  };
};



//Selector functions
export const selectTickets = (state: RootState) => state.tickets;

export const selectEstimationScale = (state: RootState) =>
  state.estimationScale;

export const selectOpinions = (state: RootState) => state.currentUser.opinions;

export const selectCurrentUser = (state: RootState) => state.currentUser;

//strange boilerplate to make the redux devtools extension work
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer as Reducer<RootState>,
  composeEnhancers()
);

export default store;
