import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  CenteredButton,
  Header2,
  Header3,
  Ul,
  Text,
  Border,
  BigInput,
  Row,
  Input,
  Button,
  Column,
} from "src/styles/components";
import {
  setEstimationScale,
  resetUserSelections,
  selectCurrentUser,
  setTickets,
  selectTickets,
} from "../../../app/store";
import api, {
  sendWSSubscritption as sendWSSubscription,
  wspInstance,
} from "../../../backendApi";
import {
  EstimationScale,
  Opinion,
  PokerStatus,
  SubscriptionType,
  Ticket,
  UpdateType,
  User,
} from "../../../structures/Types";
import EScale from "./EScale";
import DraggableTicket from "./DraggableTicket";
import { nanoid } from "nanoid";

const EMenu = () => {
  const dispatch = useDispatch();

  const { pokerUid } = useParams();

  if (pokerUid === undefined) {
    throw TypeError("the /play/:uid route should provide a valid UID");
  }

  const [currentUserUid, setCurrentUserUid] = useState("");

  const [touched, setTouched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  let [pokerName, setPokerName] = useState("");

  const [newUser, setNewUser] = useState<User>({
    name: "",
    uid: nanoid(),
    opinions: [],
  } as User);

  const navigate = useNavigate();

  //Whenever the UserMenu is loaded, we load all the relevant server from the data and put it into state until the main usermenu component is reloaded, i.e. the page is refreshed
  useEffect(() => {
    const loadData = async () => {
      const estimationScale: EstimationScale =
        await api.retrieveEstimationScale(pokerUid);
      const tickets: Ticket[] = await api.retrieveTickets(pokerUid);
      const pokerName = (await api.retrieveActivePokers(pokerUid))[0].name;

      dispatch(setEstimationScale(estimationScale.scalepoints));
      dispatch(setTickets(tickets));
      setPokerName(pokerName);

      console.log("EḾenu initialized");
    };

    loadData();

    sendWSSubscription(SubscriptionType.WatchPokerStatus, pokerUid);

    wspInstance.onMessage.addListener(async (e) => {
      console.log("received message", e, "of type", typeof e);
      let msg = JSON.parse(e);
      switch (msg.content) {
        case UpdateType.FinishPoker:
          navigate(`/results/${pokerUid}`);
      }
    });
  }, [pokerUid]);

  const tickets: Ticket[] = useSelector(selectTickets);

  const user = useSelector(selectCurrentUser);

  const confirmSelections = async (opinions: Opinion[]) => {

    if (!submitted) {
      let currentUserUid = await api.addUser({
        name: newUser.name,
        pokerUid: pokerUid,
        opinions: opinions,
      });
      setCurrentUserUid(currentUserUid);
    } else {
      api.updateUser({
        userUid: currentUserUid,
        pokerUid: pokerUid,
        opinions: opinions,
      });
      console.log("updated user");
    }

    setTouched(false);
    setSubmitted(true);
  };

  return (
    <>
      <Row style={{ height: "100vh", alignItems: "stretch" }}>
        <Border>
          <Column
            style={{
              height: "100%",
              minWidth: "10em",
              alignContent: "flex-start",
            }}
          >
            <Header3>Tickets</Header3>

            {tickets.length !== user.opinions.length ? (
              <Ul>
                {tickets
                  .filter(
                    (ticket) =>
                      !user.opinions
                        .map((opinion) => opinion.ticketUid)
                        .includes(ticket.uid)
                  )
                  .map((ticket) => (
                    <DraggableTicket
                      ticketUid={ticket.uid}
                      key={ticket.uid}
                      setTouched={setTouched}
                    />
                  ))}
              </Ul>
            ) : (
              <Border primary>
                <Text>No unestimated tickets left!</Text>
              </Border>
            )}
          </Column>
        </Border>

        <Column style={{ flexGrow: 3 }}>
          <Header2>{pokerName}</Header2>

          <EScale setTouched={setTouched} />

          <CenteredButton
            onClick={() => {
              setTouched(true);
              dispatch(resetUserSelections());
            }}
          >
            Reset selections
          </CenteredButton>

          {!touched && submitted ? (
            <Border primary>
              <Text>
                Estimations submitted! Please hold until the other players
                finish this Poker as well!
              </Text>
            </Border>
          ) : (
            <></>
          )}
        </Column>

        <Column style={{ justifyContent: "flex-end" }}>
          <Row style={{ margin: "1em" }}>
            <Header3 as="label" htmlFor="user-name-input">
              Enter your name
            </Header3>
            <Input
              id="user-name-input"
              type="text"
              value={newUser.name}
              onChange={(e) =>
                setNewUser({ ...newUser, name: e.target.value || "" })
              }
              disabled={submitted}
            />
            <Button onClick={() => confirmSelections(user.opinions)}>
              {submitted ? "Update!" : "Go!"}
            </Button>
          </Row>
        </Column>
      </Row>
    </>
  );
};

export default EMenu;
