import EstimationScalePoint from "./EScalePoint";
import { ScalePoint } from "../../../structures/Types";
import { useSelector } from "react-redux";
import { selectEstimationScale } from "../../../app/store";
import { Row } from "src/styles/components";

type EScaleProps = {
  setTouched: Function
}

const EScale = ({setTouched: setTouched} : EScaleProps) => {
  const estimationScale = useSelector(selectEstimationScale);

  return (
     <Row>
        {estimationScale.scalepoints.map((scalePoint: ScalePoint) => (
          <EstimationScalePoint
            value={scalePoint.value}
            uid={scalePoint.uid}
            key={scalePoint.value}
            setTouched={setTouched}
          />
        ))}
      </Row>
);
}

export default EScale;
