import { DropResult, EstimationScale, Ticket } from "../../../structures/Types";
import {
  CenteredButton,
  Text,
  Column,
  ListItem,
  Border,
} from "../../../styles/components";

import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  createOpinion,
  selectEstimationScale,
  selectTickets,
} from "../../../app/store";

type DraggableTicketProps = {
  ticketUid: string;
  setTouched: Function;
};

const DraggableTicket = (props: DraggableTicketProps) => {
  const estimationScale: EstimationScale = useSelector(selectEstimationScale);
  const tickets: Ticket[] = useSelector(selectTickets);
  const dispatch = useDispatch();

  //get the ticket belonging to this React component using filter
  const activeTicket: Ticket = tickets.filter((ticket) => {
    return ticket.uid === props.ticketUid;
  })[0];

  const [ticketInfoVisibility, setTicketInfoVisibility] =
    useState(false);

  function handleDrop(dropTargetUid: string) {
    console.log(
      ` You dropped ticket ${props.ticketUid} onto the scalepoint with UID ${dropTargetUid}`
    );

    //update the UI to indicate that the latest changes haven't been submitted
    props.setTouched(true);

    dispatch(
      createOpinion({
        opinionUid: dropTargetUid,
        ticketUid: activeTicket.uid,
      })
    );
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "ticket",
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        currentItem: monitor.getItem(),
      }),
      end: (_, monitor) => {
        let dropTarget: DropResult = monitor.getDropResult() || {
          scalePointUid: "",
        };
        if (dropTarget.scalePointUid !== "") {
          handleDrop(dropTarget.scalePointUid);
        }
      },
    }),
    [estimationScale]
  );

  return (
    <Column style={{ margin: "auto" }}>
      <Border
        primary
        ref={drag}
        style={isDragging ? { display: "none" } : { display: "block" }}
      >
        <ListItem key={activeTicket.uid}>
          <Column style={{ margin: "auto" }}>
            <Text>{activeTicket.name}</Text>
            {activeTicket.desc !== "" && activeTicket.desc !== null ? (
              <>
                <CenteredButton
                  onClick={() => {setTicketInfoVisibility(!ticketInfoVisibility); console.log('clicke!!!')}}
                >
                  {ticketInfoVisibility ? "Show less" : "Show more"}
                </CenteredButton>
                {ticketInfoVisibility ? <Text>{activeTicket.desc}</Text> : <></> }
              </>
            ) : (
              <></>
            )}
          </Column>
        </ListItem>
      </Border>
    </Column>
  );
};

export default DraggableTicket;
