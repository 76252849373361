import { jsonToGraphQLQuery } from "json-to-graphql-query";
import {
  AddUserPayload,
  CreatePokerPayload,
  EstimationScale,
  Opinion,
  Poker,
  PokerStatus,
  Ticket,
  User,
  MessageType,
  WebSocketMessage,
  PokerMetaData,
  UpdateType,
  SubscriptionType,
  UpdateUserPayload,
} from "./structures/Types";

import { config } from "./constants";

import WebSocketAsPromised from "websocket-as-promised";

export const wspInstance = new WebSocketAsPromised(
  /* "wss://planning-poker-backend.cr.trineria.fi/websocket" */
/*   "ws://localhost:8080/websocket", */
    config.wsUrl,
  {
    packMessage: (data) => JSON.stringify(data),
    //we have to typecast data to a string because in theory it could also be Blob (though it should never be but the compiler doesn't know that)
    unpackMessage: (data) => JSON.parse(data as string),
    attachRequestId: (data, requestId) => {
      return { ...data, id: requestId };
    }, // attach requestId to message as `id` field

    //TODO the documentation said to do this weird AND operation here, does this make any sense?
    extractRequestId: (data) => data && data.id, // read requestId from message `id` field});
  }
);


export const parseGqlResponse = (response: string) => {
  let parsed = JSON.parse(response);

  let data = parsed.content.Content.Fields[0].data;

  return data;
};

// a small wrapper for sending queries
const sendWSRequest = async (payload: WebSocketMessage) => {
  await wspInstance.open();
  let response = await wspInstance.sendRequest(payload);
  
  return JSON.stringify(response);
};

export const sendWSUpdate = async (updateType: UpdateType, pokerUid: string) => {
  await wspInstance.open();

  let content = {updateType: updateType, pokerUid: pokerUid}
  wspInstance.send(
    JSON.stringify({ messageType: MessageType.StatusUpdate, content: content, id: 0 })
  );
};

export const sendWSSubscritption = async (subscriptionType: SubscriptionType, pokerUid: string) =>  {
  await wspInstance.open();

  let content = {subscriptionType: subscriptionType, pokerUid: pokerUid}
  wspInstance.send(
    JSON.stringify({ messageType: MessageType.Subscription, content: content, id: 0 })
  );
};


const addUser = async (payload: AddUserPayload) => {
  const m = jsonToGraphQLQuery({
    mutation: {
      addUser: {
        __args: {
          payload: {
            ...payload,
          },
        },
        uid: true,
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: m,
  });

  await sendWSUpdate(UpdateType.RefreshUsers, payload.pokerUid);

  let parsedResponse = parseGqlResponse(response);

  return parsedResponse.addUser.uid as string;
};

const updateUser = async (payload: UpdateUserPayload) => {
  const m = jsonToGraphQLQuery({
    mutation: {
      updateUser: {
        __args: {
          payload: {
            ...payload,
          },
        },
        uid: true,
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: m,
  });

  await sendWSUpdate(UpdateType.RefreshUsers, payload.pokerUid);

  let parsedResponse = parseGqlResponse(response);

  return parsedResponse.updateUser.uid as string;
};





const createPoker = async (payload: CreatePokerPayload) => {
  console.log(JSON.stringify(payload));

  let m = jsonToGraphQLQuery({
    mutation: {
      createPoker: {
        __args: {
          payload: {
            ...payload,
          },
        },
        uid: true,
      },
    },
  });

  console.log("inside createPoker()");
  console.log(m);

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: m,
  });

  let parsedResponse = parseGqlResponse(response);

  console.log("response to createPoker()", parsedResponse);

  return parsedResponse.createPoker.uid as string;
};

const retrieveUsers = async (pokerUid: string) => {
  let q = jsonToGraphQLQuery({
    query: {
      pokers: {
        __args: {
          uid: pokerUid,
        },
        users: {
          name: true,
          uid: true,
          opinions: {
            ticketUid: true,
            opinionUid: true,
          },
        },
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: q,
  });

  let parsedResponse = parseGqlResponse(response);

  
  return (parsedResponse.pokers[0].users || []) as User[];
};

const retrieveTickets = async (pokerUid: string) => {
  let q = jsonToGraphQLQuery({
    query: {
      pokers: {
        __args: {
          uid: pokerUid,
        },
        tickets: {
          name: true,
          uid: true,
          desc: true
        },
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: q,
  });

  let parsedResponse = parseGqlResponse(response);

  return parsedResponse.pokers[0].tickets as Ticket[];
};

const retrieveActivePokers = async (pokerUid?: string) => {
  let q = "";

  if (pokerUid === undefined) {
    q = jsonToGraphQLQuery({
      query: {
        pokers: {
          name: true,
          uid: true,
          pokerStatus: true,
        },
      },
    });
  } else {
    q = jsonToGraphQLQuery({
      query: {
        pokers: {
          __args: {
            uid: pokerUid,
          },
          name: true,
          uid: true,
          pokerStatus: true,
        },
      },
    });
  }

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: q,
  });

  let parsedResponse = parseGqlResponse(response);

  return parsedResponse.pokers as PokerMetaData[];
};

const retrieveEstimationScale = async (pokerUid: string) => {
  let q = jsonToGraphQLQuery({
    query: {
      pokers: {
        __args: {
          uid: pokerUid,
        },
        estimationScale: {
          scalepoints: {
            value: true,
            uid: true,
          },
        },
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: q,
  });

  let parsedResponse = parseGqlResponse(response);

  console.log(parsedResponse);

  return parsedResponse.pokers[0].estimationScale as EstimationScale;
};

const retrievePokerStatus = async (pokerUid: string) => {
  let q = jsonToGraphQLQuery({
    query: {
      pokers: {
        __args: {
          uid: pokerUid,
        },
        pokerStatus: true,
      },
    },
  });

  let response = await sendWSRequest({
    messageType: MessageType.Query,
    content: q,
  });

  let parsedResponse = parseGqlResponse(response);

  console.log(parsedResponse);

  return parsedResponse.pokers[0].pokerStatus as PokerStatus;
};

const api = {
  addUser,
  updateUser,
  retrieveTickets,
  retrieveEstimationScale,
  retrievePokerStatus,
  retrieveUsers,
  retrieveActivePokers,
  createPoker,
};

export default api;
