import { time } from "console";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import api from "../../../backendApi";
import {
  EstimationScale,
  Opinion,
  Result,
  ScalePoint,
  Ticket,
  User,
} from "../../../structures/Types";
import {
  Border,
  Column,
  Header1,
  Header2,
  ListItem,
  Ol,
  Text,
} from "../../../styles/components";

const PokerResults = () => {
  const pokerUid = useParams().pokerUid;

  if (pokerUid === undefined) {
    throw TypeError("the /results/:uid route should provide a valid UID");
  }

  const [results, setResults] = useState<Result[]>([]);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [estimationScale, setEstimationScale] = useState<EstimationScale>({
    scalepoints: [],
  });
  const [pokerName, setPokerName] = useState("");

  useEffect(() => {
    console.log("inside useEffect");

    const loadData = async () => {
      let retrievedUsers = await api.retrieveUsers(pokerUid);
      console.log("retrievedUsers in useEffect", retrievedUsers);

      setUsers(retrievedUsers);

      console.log("users in useEffect", users);

      let retrievedEstimationScale = await api.retrieveEstimationScale(
        pokerUid
      );
      setEstimationScale(retrievedEstimationScale);

      let retrievedTickets = await api.retrieveTickets(pokerUid);
      setTickets(retrievedTickets);

      let retrievedPokerName = (await api.retrieveActivePokers(pokerUid))[0]
        .name;
      setPokerName(retrievedPokerName);
    };

    loadData();
  }, [pokerUid]);

  useEffect(() => {
    evaluateResults();
  }, [tickets, users, estimationScale]);

  //checks if all tickets have been evaluated by all users
  /* const checkResults = () => {
    return (
      users.every((user) => user.opinions.length === tickets.length) &&
      tickets.length !== 0
    );
  }; */

  const evaluateResults = () => {
    //await new Promise(r => setTimeout(r, 5000));

    if (
      tickets.length === 0 ||
      users.length === 0 ||
      estimationScale.scalepoints.length === 0
    ) {
      console.log("nothing to evaluate");
    }
    const allOpinions: Opinion[] = users
      .map((user) => user.opinions)
      .reduce((flat, next) => flat.concat(next), []);

    const results: Result[] = [];

    tickets.forEach((ticket: Ticket) => {
      console.log(ticket.name);
      //for each ticket, find all the opinions relating to it
      const relatedOpinions = allOpinions.filter(
        (opinion) => opinion.ticketUid === ticket.uid
      );

      const relatedOpinionUids: string[] = relatedOpinions.map(
        (opinion) => opinion.opinionUid
      );

      console.log(relatedOpinionUids);

      //from those opinions, find all the scalepoints related to this ticket

      let relatedScalePoints: ScalePoint[] = [];

      for (let i = 0; i < relatedOpinionUids.length; i++) {
        relatedScalePoints.push(
          estimationScale.scalepoints.filter((scalePoint) =>
            relatedOpinionUids.includes(scalePoint.uid)
          )[0]
        );
      }


      console.log(relatedScalePoints);

      //map the scalepoints to their numerical values
      const relatedNumericalEstimations = relatedScalePoints.map(
        (scalePoint) => scalePoint.value
      );

      const estimationAverage =
        relatedNumericalEstimations.reduce((total, next) => (total += next)) /
        users.length;

      results.push({
        ticketName: ticket.name,
        estimationValue: estimationAverage,
      });
    });

    //finally, sort them by value
    setResults(
      results.sort((a, b) => (a.estimationValue > b.estimationValue ? 1 : -1))
    );
  };

  return (
    <Column>
      <Header1>{pokerName}</Header1>
      <Header2>Poker Results</Header2>
      <Border>
        <Ol>
          {results.map((result) => {
            return (
              <ListItem key={result.ticketName}>
                <Text>{result.ticketName}</Text>
                <Text>{result.estimationValue.toFixed(2)}</Text>
              </ListItem>
            );
          })}
        </Ol>
      </Border>
    </Column>
  );
};

export default PokerResults;
